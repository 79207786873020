import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import {
    Button,
    Card,
    CardContent,
    CardHeader,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import React from 'react';
import ProductAdd from '../../product/ProductAdd/ProductAdd';
import './WorkOrderProducts.scss';
import { WorkOrderProductsProps, WorkOrderProductsState } from './WorkOrderProducts.types';
import { configuration$ } from '@phrospr/lib-web-core';
import { IWorkOrderLineEntity } from '@phrospr/lib-models';
import { IProductLineEntity } from '@phrospr/lib-models/src/product/product-line.entity';

const buttonStyle = { padding: '1px 0 5px 0!important' };

export class WorkOrderProducts extends React.Component<WorkOrderProductsProps, WorkOrderProductsState> {
    inputWorkOrderLines: IWorkOrderLineEntity[];

    constructor(props: WorkOrderProductsProps) {
        super(props);
        this.inputWorkOrderLines = props.workOrderLines;
        this.state = {
            workOrderLines: props.workOrderLines,
            showDialogAddProduct: false,
        };
    }

    async componentDidMount() {
        const configuration = await configuration$;
        this.setState({ configuration });
    }

    publishChanges() {
        this.props.onChange({
            workOrderLines: this.state.workOrderLines ?? [],
        });
    }

    deleteWorkOrderLine(index: number) {
        this.state.workOrderLines.splice(index, 1);
        this.setState({ workOrderLines: this.state.workOrderLines });
    }

    transformWorkOrderLine(productLine: IProductLineEntity): IWorkOrderLineEntity {
        //  transformation productLineupsertProductLine
        return {
            product: productLine.product,
            product_id: productLine.product.product_id,
            quantity: productLine.quantity, // Default value for `quantity`
            data: {},
            creation_date: new Date(),
            work_order_line_id: undefined,
            work_order_id: undefined,
        };
    }

    render() {
        return (
            <div id={'work-order-products'}>
                <Card sx={{ minWidth: 275, maxWidth: '100%', marginTop: '10px' }}>
                    <CardHeader title={'Products'} />
                    <CardContent>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650, padding: '0px' }} size={'small'} aria-label={'a dense table'}>
                                <TableHead>
                                    <TableCell>Product nummer</TableCell>
                                    <TableCell>Product naam</TableCell>
                                    <TableCell>Beschrijving</TableCell>
                                    <TableCell>Aantal</TableCell>
                                    <TableCell></TableCell>
                                </TableHead>
                                <TableBody sx={{ padding: '0px' }}>
                                    {this.state.workOrderLines?.map((workOrderLine, index) => (
                                        <TableRow key={index}>
                                            <TableCell>{workOrderLine.product?.product_number}</TableCell>
                                            <TableCell>{workOrderLine.product?.name}</TableCell>
                                            <TableCell>{workOrderLine.product?.specifications?.description}</TableCell>
                                            <TableCell>{workOrderLine.quantity}</TableCell>
                                            <TableCell>
                                                {' '}
                                                <Button
                                                    variant={'text'}
                                                    startIcon={<DeleteIcon />}
                                                    onClick={() => this.deleteWorkOrderLine(index)}
                                                ></Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <Grid container direction={'row'} justifyContent={'center'} alignItems={'center'}>
                            <Grid item>
                                <Button
                                    variant={'text'}
                                    startIcon={<AddIcon />}
                                    onClick={() => this.setState({ showDialogAddProduct: true })}
                                >
                                    Add
                                </Button>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
                {this.state.showDialogAddProduct ? (
                    <ProductAdd
                        upsertProductLine={productLine => {
                            const transformedWorkOrderLine = this.transformWorkOrderLine(productLine);

                            this.setState(
                                {
                                    workOrderLines: [...(this.state.workOrderLines ?? []), transformedWorkOrderLine],
                                    showDialogAddProduct: false,
                                },
                                () => this.publishChanges(),
                            );
                        }}
                        showDialogAddProduct={this.state.showDialogAddProduct}
                        dialogClosed={() => this.setState({ showDialogAddProduct: false, workOrderLines: [] })}
                    ></ProductAdd>
                ) : undefined}
            </div>
        );
    }
}

export default WorkOrderProducts;
