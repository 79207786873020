import { Card, CardContent, CardHeader } from '@mui/material';
import { IAddress, IClientEntity } from '@phrospr/api-backend';
import React from 'react';
import './ClientCard.scss';
import { ClientCardProps, ClientCardState } from './ClientCard.types';

export class ClientCard extends React.Component<ClientCardProps, ClientCardState> {
    client: IClientEntity;
    deliveryAddress: IAddress;

    constructor(props: ClientCardProps) {
        super(props);
        this.client = props.client;
        this.deliveryAddress = props.deliveryAddress;
        this.state = { loaded: true };
    }

    render() {
        return (
            <Card sx={{ minWidth: 400 }} style={{ maxWidth: '600px' }}>
                <CardHeader title={'Klanten fiche'} />
                <CardContent>
                    <table>
                        <tr>
                            <td>Naam:</td>
                            <td>{this.client?.client_data?.first_name}</td>
                        </tr>
                        <tr>
                            <td>Email:</td>
                            <td>{this.client?.client_data?.email}</td>
                        </tr>
                        <tr>
                            <td>Tel nr:</td>
                            <td>{this.client?.client_data?.telephone_phone}</td>
                        </tr>
                        <tr>
                            <td>Gsm nr:</td>
                            <td>{this.client?.client_data?.mobile_phone}</td>
                        </tr>
                        <tr>
                            <td>BE nr:</td>
                            <td>{this.client?.client_data?.company_data?.bank_account}</td>
                        </tr>
                        <tr>
                            <td>Hoofdadres:</td>
                            <td>{JSON.stringify(this.client?.client_data?.main_address ?? {})}</td>
                        </tr>
                        <tr>
                            <td>Factuuradres:</td>
                            <td>{JSON.stringify(this.deliveryAddress ?? {})}</td>
                        </tr>
                    </table>
                </CardContent>
            </Card>
        );
    }
}

export default ClientCard;
