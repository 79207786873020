import React from 'react';
import { WorkOrderFilterProps, WorkOrderFilterState } from './WorkOrderFilter.types';
import './WorkOrderFilter.scss';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import TextField from '@mui/material/TextField';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { WorkOrderStatus, WorkOrderType } from '@phrospr/lib-models';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export class WorkOrderFilter extends React.Component<WorkOrderFilterProps, WorkOrderFilterState> {
    constructor(props: WorkOrderFilterProps) {
        super(props);
        this.state = {
            workOrderSearchFilter: {
                client_name: null,
                work_order_id: null,
                client_number: null,
                work_order_states: [
                    WorkOrderStatus.created,
                    WorkOrderStatus.cancelled,
                    WorkOrderStatus.finished,
                    WorkOrderStatus.invoiced,
                ],
                work_order_types: [
                    WorkOrderType.adaptInstallation,
                    WorkOrderType.closeSwimmingPool,
                    WorkOrderType.startUpSwimmingPool,
                    WorkOrderType.newConstruction,
                    WorkOrderType.defectShutter,
                    WorkOrderType.defectDisinfection,
                    WorkOrderType.inBetweenService,
                    WorkOrderType.repair,
                    WorkOrderType.renovation,
                    WorkOrderType.restockProducts,
                ],
                from_created_date: null,
                to_created_date: null,
                postcode: null,
                work_order_technician_user_name: null,
            },
        };
        this.props.onChange(this.state.workOrderSearchFilter);
    }

    updateSearch = async () => {
        this.props.onChange(this.state.workOrderSearchFilter);
    };

    resetFilters = async () => {
        await this.setState({
            workOrderSearchFilter: {
                client_name: null,
                work_order_id: null,
                client_number: null,
                work_order_states: [
                    WorkOrderStatus.created,
                    WorkOrderStatus.cancelled,
                    WorkOrderStatus.finished,
                    WorkOrderStatus.invoiced,
                ],
                work_order_types: [
                    WorkOrderType.adaptInstallation,
                    WorkOrderType.closeSwimmingPool,
                    WorkOrderType.startUpSwimmingPool,
                    WorkOrderType.newConstruction,
                    WorkOrderType.defectShutter,
                    WorkOrderType.defectDisinfection,
                    WorkOrderType.inBetweenService,
                    WorkOrderType.repair,
                    WorkOrderType.renovation,
                    WorkOrderType.restockProducts,
                ],
                from_created_date: null,
                to_created_date: null,
                postcode: null,
                work_order_technician_user_name: null,
            },
        });
        console.log(this.state);
        this.updateSearch();
    };

    render() {
        return (
            <div>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid spacing={2} container direction="row" justifyContent="flex-start" alignItems="center">
                        <Grid item xs>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DatePicker
                                    label={'From'}
                                    value={this.state.workOrderSearchFilter.from_created_date}
                                    onChange={event =>
                                        this.setState(
                                            {
                                                workOrderSearchFilter: {
                                                    ...this.state.workOrderSearchFilter,
                                                    from_created_date: event,
                                                },
                                            },
                                            () => this.updateSearch(),
                                        )
                                    }
                                    renderInput={params => <TextField size="small" {...params} />}
                                />
                            </LocalizationProvider>

                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DatePicker
                                    label={'to'}
                                    value={this.state.workOrderSearchFilter.to_created_date}
                                    onChange={event =>
                                        this.setState(
                                            {
                                                workOrderSearchFilter: {
                                                    ...this.state.workOrderSearchFilter,
                                                    to_created_date: event,
                                                },
                                            },
                                            () => this.updateSearch(),
                                        )
                                    }
                                    renderInput={params => <TextField size="small" {...params} />}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs>
                            <TextField
                                type="number"
                                size="small"
                                id="work-order-id-search"
                                label="Zoek op ID"
                                variant="standard"
                                value={this.state.workOrderSearchFilter.work_order_id ?? ''}
                                onChange={event =>
                                    this.setState(
                                        {
                                            workOrderSearchFilter: {
                                                ...this.state.workOrderSearchFilter,
                                                work_order_id: parseInt(event.target.value),
                                            },
                                        },
                                        () => this.updateSearch(),
                                    )
                                }
                            />
                        </Grid>
                        <Grid item xs>
                            <TextField
                                size="small"
                                id="name-search"
                                label="Zoek op naam"
                                variant="standard"
                                value={this.state.workOrderSearchFilter.client_name ?? ''}
                                onChange={event =>
                                    this.setState(
                                        {
                                            workOrderSearchFilter: {
                                                ...this.state.workOrderSearchFilter,
                                                client_name: event.target.value,
                                            },
                                        },
                                        () => this.updateSearch(),
                                    )
                                }
                            />
                        </Grid>
                        <Grid item xs>
                            <TextField
                                size="small"
                                id="zip-code-search"
                                label="Zoek op postcode"
                                variant="standard"
                                value={this.state.workOrderSearchFilter.postcode ?? ''}
                                onChange={event =>
                                    this.setState(
                                        {
                                            workOrderSearchFilter: {
                                                ...this.state.workOrderSearchFilter,
                                                postcode: event.target.value,
                                            },
                                        },
                                        () => this.updateSearch(),
                                    )
                                }
                            />
                        </Grid>
                        <Grid item xs>
                            <TextField
                                size="small"
                                id="sold-by-search"
                                label="Zoek op technieker"
                                variant="standard"
                                value={this.state.workOrderSearchFilter.work_order_technician_user_name ?? ''}
                                onChange={event =>
                                    this.setState(
                                        {
                                            workOrderSearchFilter: {
                                                ...this.state.workOrderSearchFilter,
                                                work_order_technician_user_name: event.target.value,
                                            },
                                        },
                                        () => this.updateSearch(),
                                    )
                                }
                            />
                        </Grid>
                        <Grid item xs>
                            <FormControl sx={{ m: 1, width: 180 }}>
                                <InputLabel id="demo-multiple-checkbox-label">Status</InputLabel>
                                <Select
                                    size="small"
                                    labelId="status-checkbox"
                                    id="status-multiple-checkbox"
                                    multiple
                                    label={'status'}
                                    value={this.state.workOrderSearchFilter.work_order_states}
                                    onChange={event =>
                                        this.setState(
                                            {
                                                workOrderSearchFilter: {
                                                    ...this.state.workOrderSearchFilter,
                                                    work_order_states: event.target.value as WorkOrderStatus[],
                                                },
                                            },
                                            () => this.updateSearch(),
                                        )
                                    }
                                    input={<OutlinedInput label="status" />}
                                    renderValue={(selected: string[]) => `${selected.length} geselecteerd`}
                                    MenuProps={MenuProps}
                                >
                                    {Object.keys(WorkOrderStatus).map(status => (
                                        <MenuItem key={status} value={WorkOrderStatus[status]}>
                                            <Checkbox
                                                checked={this.state.workOrderSearchFilter.work_order_states.includes(
                                                    WorkOrderStatus[status],
                                                )}
                                            />
                                            <ListItemText primary={WorkOrderStatus[status]} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs>
                            <FormControl sx={{ m: 1, width: 180 }}>
                                <InputLabel id="demo-multiple-checkbox-label">Type</InputLabel>
                                <Select
                                    size="small"
                                    labelId="type-checkbox"
                                    id="type-multiple-checkbox"
                                    multiple
                                    label={'type'}
                                    value={this.state.workOrderSearchFilter.work_order_types}
                                    onChange={event =>
                                        this.setState(
                                            {
                                                workOrderSearchFilter: {
                                                    ...this.state.workOrderSearchFilter,
                                                    work_order_types: event.target.value as WorkOrderType[],
                                                },
                                            },
                                            () => this.updateSearch(),
                                        )
                                    }
                                    input={<OutlinedInput label="status" />}
                                    renderValue={(selected: string[]) => `${selected.length} geselecteerd`}
                                    MenuProps={MenuProps}
                                >
                                    {Object.keys(WorkOrderType).map(type => (
                                        <MenuItem key={type} value={WorkOrderType[type]}>
                                            <Checkbox
                                                checked={this.state.workOrderSearchFilter.work_order_types.includes(
                                                    WorkOrderType[type],
                                                )}
                                            />
                                            <ListItemText primary={WorkOrderType[type]} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid>
                            <Button size={'small'} startIcon={<CloseIcon />} onClick={() => this.resetFilters()}>
                                Clear filter
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </div>
        );
    }
}

export default WorkOrderFilter;
