import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    OnChanges,
    OnDestroy,
    Output,
    SimpleChanges,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import * as ReactDom from 'react-dom/client';
import * as React from 'react';
import { WorkOrderOverview, ShadowDom } from '@phrospr/frontend-react';
import { REACT_CONTAINER } from '../../../../constants';
import { Router } from '@angular/router';

@Component({
    selector: 'pr-work-order-overview-page',
    styleUrls: ['./work-order-overview-page.component.scss'],
    template: `<span #${REACT_CONTAINER}></span>`,
    encapsulation: ViewEncapsulation.ShadowDom,
})
export class WorkOrderOverviewPageComponent implements OnChanges, OnDestroy, AfterViewInit {
    @ViewChild(REACT_CONTAINER, { static: true }) containerRef: ElementRef;
    private component: ReactDom.Root;
    element: HTMLElement;

    public constructor(public router: Router) {}
    async ngAfterViewInit() {
        this.element = this.containerRef.nativeElement as HTMLElement;
        this.component = ReactDom.createRoot(this.element);
        await this.render();
    }

    async ngOnChanges(changes: SimpleChanges) {
        await this.render();
    }

    ngOnDestroy(): void {
        this.component.unmount();
    }

    async goToWorkOrderEdit({ workOrderId }) {
        await this.router.navigateByUrl(`/work-order-upsert?work_order_id=${workOrderId}`);
    }

    private render() {
        if (!this.component) return console.log('failed to render, no component yet', typeof this);
        this.component.render(
            <ShadowDom element={this.element}>
                <WorkOrderOverview
                    goToEditWorkOrder={(workOrderId: number) => this.goToWorkOrderEdit({ workOrderId })}
                    routeNavigator={(route: string) => this.router.navigateByUrl(route)}
                />
            </ShadowDom>,
        );
    }
}
