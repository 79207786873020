import {
    AfterViewInit,
    Component,
    ElementRef,
    OnChanges,
    OnDestroy,
    SimpleChanges,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import * as ReactDom from 'react-dom/client';
import * as React from 'react';
import { first } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { ShadowDom, WorkOrderUpsertPage } from '@phrospr/frontend-react';
import { REACT_CONTAINER } from '../../../../constants';

@Component({
    selector: 'pr-upsert-work-order-page-component',
    template: `<span #${REACT_CONTAINER}></span>`,
    encapsulation: ViewEncapsulation.ShadowDom,
})
export class UpsertWorkOrderComponent implements OnChanges, OnDestroy, AfterViewInit {
    @ViewChild(REACT_CONTAINER, { static: true }) containerRef: ElementRef;
    private component: ReactDom.Root;
    element: HTMLElement;

    private readonly workOrderId$: Promise<number>;
    private readonly clientId$: Promise<number>;

    constructor(private router: Router, private route: ActivatedRoute) {
        this.workOrderId$ = this.route.queryParams
            .pipe(first())
            .toPromise()
            .then(params => {
                if (params.work_order_id !== undefined) return parseInt(params.work_order_id, 10);
                return undefined;
            });
        this.clientId$ = this.route.queryParams
            .pipe(first())
            .toPromise()
            .then(params => {
                if (params.client_id !== undefined) return parseInt(params.client_id, 10);
                return undefined;
            });
    }

    async ngAfterViewInit() {
        this.element = this.containerRef.nativeElement as HTMLElement;
        this.component = ReactDom.createRoot(this.element);
        await this.render();
    }

    async ngOnChanges(changes: SimpleChanges) {
        await this.render();
    }

    ngOnDestroy(): void {
        this.component.unmount();
    }

    private async render() {
        if (!this.component) return console.log('failed to render, no component yet', typeof this);
        this.component.render(
            <ShadowDom element={this.element}>
                <WorkOrderUpsertPage
                    workOrderId={await this.workOrderId$}
                    clientId={await this.clientId$}
                    onSuccess={() => this.router.navigateByUrl('/work-order')}
                    onCancel={() => this.router.navigateByUrl('/work-order')}
                />
            </ShadowDom>,
        );
    }
}
