export enum WorkOrderType {
    startUpSwimmingPool = 'Opstart zwembad',
    closeSwimmingPool = 'Afsluiten zwembad',
    inBetweenService = 'Tussentijdse service',
    defectDisinfection = 'Defect desinfectie',
    defectShutter = 'Defect rolluik',
    adaptInstallation = 'Aanpassen installatie',
    newConstruction = 'Nieuwbouw',
    repair = 'Repair',
    renovation = 'Renovatie',
    restockProducts = 'Bijvullen producten',
}
