import { Button, Dialog, DialogActions, DialogContent, FormGroup, TextField } from '@mui/material';
import { ClientApi } from '@phrospr/api-backend';
import React, { Component } from 'react';
import * as toastr from 'toastr';
import { ClientFicheEditDialogProps, ClientFicheEditDialogState } from './ClientFicheEdit.types';

export class ClientFicheEdit extends Component<ClientFicheEditDialogProps, ClientFicheEditDialogState> {
    constructor(props: ClientFicheEditDialogProps) {
        super(props);
        this.state = {
            open: this.props.open,
            clientToChange: this.props.client,
            deliveryAddressToChange: this.props.selectedDeliveryAddress,
        };
    }

    closeDialog = () => {
        this.setState({ open: false });
        if (this.props.dialogClosed) this.props.dialogClosed();
    };

    saveClient = async () => {
        try {
            if (this.props.selectedDeliveryAddressIndex === 0) {
                const clientDataToChange = this.state.clientToChange.client_data;
                clientDataToChange.main_address = this.state.deliveryAddressToChange;
                this.setState({
                    clientToChange: {
                        ...this.state.clientToChange,
                        client_data: clientDataToChange,
                    },
                });
            } else {
                //still need to update a specific part of the array
                const deliveryAddressToChange = this.state.clientToChange.client_data.delivery_addresses;
                deliveryAddressToChange[this.props.selectedDeliveryAddressIndex - 1] =
                    this.state.deliveryAddressToChange;
                this.setState({
                    clientToChange: {
                        ...this.state.clientToChange,
                        client_data: {
                            ...this.state.clientToChange.client_data,
                            delivery_addresses: deliveryAddressToChange,
                        },
                    },
                });
            }
            await ClientApi.updateClient(this.state.clientToChange);
            this.props.clientSaved();
            this.closeDialog();
        } catch (e) {
            console.log(e);
            toastr.error('something went wrong updating the client');
        }
    };

    render() {
        return (
            <Dialog open={this.props.open} maxWidth={'lg'}>
                <DialogContent>
                    <FormGroup>
                        <TextField
                            required
                            label={'First name'}
                            defaultValue={this.state.clientToChange.client_data.first_name}
                            onChange={event =>
                                this.setState({
                                    clientToChange: {
                                        ...this.state.clientToChange,
                                        client_data: {
                                            ...this.state.clientToChange.client_data,
                                            first_name: event.target.value,
                                        },
                                    },
                                })
                            }
                            variant={'standard'}
                        />
                        <TextField
                            required
                            label={'Last name'}
                            defaultValue={this.state.clientToChange.client_data.last_name}
                            onChange={event =>
                                this.setState({
                                    clientToChange: {
                                        ...this.state.clientToChange,
                                        client_data: {
                                            ...this.state.clientToChange.client_data,
                                            last_name: event.target.value,
                                        },
                                    },
                                })
                            }
                            variant={'standard'}
                        />
                        <TextField
                            label={'Email'}
                            defaultValue={this.state.clientToChange.client_data.email}
                            onChange={event =>
                                this.setState({
                                    clientToChange: {
                                        ...this.state.clientToChange,
                                        client_data: {
                                            ...this.state.clientToChange.client_data,
                                            email: event.target.value,
                                        },
                                    },
                                })
                            }
                            variant={'standard'}
                        />
                        <TextField
                            label={'Tel nr.'}
                            defaultValue={this.state.clientToChange.client_data.telephone_phone}
                            onChange={event =>
                                this.setState({
                                    clientToChange: {
                                        ...this.state.clientToChange,
                                        client_data: {
                                            ...this.state.clientToChange.client_data,
                                            telephone_phone: event.target.value,
                                        },
                                    },
                                })
                            }
                            variant={'standard'}
                        />
                        <TextField
                            label={'GSM nr.'}
                            defaultValue={this.state.clientToChange.client_data.mobile_phone}
                            onChange={event =>
                                this.setState({
                                    clientToChange: {
                                        ...this.state.clientToChange,
                                        client_data: {
                                            ...this.state.clientToChange.client_data,
                                            mobile_phone: event.target.value,
                                        },
                                    },
                                })
                            }
                            variant={'standard'}
                        />
                        <TextField
                            label={'Bankrek nr.'}
                            defaultValue={this.state.clientToChange.client_data.company_data.bank_account}
                            onChange={event =>
                                this.setState({
                                    clientToChange: {
                                        ...this.state.clientToChange,
                                        client_data: {
                                            ...this.state.clientToChange.client_data,
                                            company_data: {
                                                ...this.state.clientToChange.client_data.company_data,
                                                bank_account: event.target.value,
                                            },
                                        },
                                    },
                                })
                            }
                            variant={'standard'}
                        />
                        <TextField
                            label={'Btw nr.'}
                            defaultValue={this.state.clientToChange.client_data.company_data.vat_number}
                            onChange={event =>
                                this.setState({
                                    clientToChange: {
                                        ...this.state.clientToChange,
                                        client_data: {
                                            ...this.state.clientToChange.client_data,
                                            company_data: {
                                                ...this.state.clientToChange.client_data.company_data,
                                                vat_number: event.target.value,
                                            },
                                        },
                                    },
                                })
                            }
                            variant={'standard'}
                        />
                        <TextField
                            label={'Adres'}
                            defaultValue={this.state.deliveryAddressToChange.address}
                            onChange={event =>
                                this.setState({
                                    deliveryAddressToChange: {
                                        ...this.state.deliveryAddressToChange,
                                        address: event.target.value,
                                    },
                                })
                            }
                            variant={'standard'}
                        />
                        <TextField
                            label={'Postcode'}
                            defaultValue={this.state.deliveryAddressToChange.zip_code}
                            onChange={event =>
                                this.setState({
                                    deliveryAddressToChange: {
                                        ...this.state.deliveryAddressToChange,
                                        zip_code: event.target.value,
                                    },
                                })
                            }
                            variant={'standard'}
                        />
                        <TextField
                            label={'Stad'}
                            defaultValue={this.state.deliveryAddressToChange.city}
                            onChange={event =>
                                this.setState({
                                    deliveryAddressToChange: {
                                        ...this.state.deliveryAddressToChange,
                                        city: event.target.value,
                                    },
                                })
                            }
                            variant={'standard'}
                        />
                    </FormGroup>
                </DialogContent>
                <DialogActions>
                    <Button size={'small'} onClick={this.closeDialog}>
                        Cancel
                    </Button>
                    <Button color={'primary'} variant={'contained'} size={'small'} onClick={this.saveClient}>
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default ClientFicheEdit;
