import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import {
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    FormControlLabel,
    FormGroup,
    Grid,
    Input,
    InputAdornment,
    MenuItem,
    Paper,
    Select,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import { GlobalApi, IQuoteLineEntity } from '@phrospr/api-backend';
import React from 'react';
import ProductAdd from '../../product/ProductAdd/ProductAdd';
import './QuoteProducts.scss';
import { QuoteProductsProps, QuoteProductsState } from './QuoteProducts.types';
import { calculateQuotePrices } from '@phrospr/lib-core';
import { configuration$ } from '@phrospr/lib-web-core';
import { IProductLineEntity } from '@phrospr/lib-models/src/product/product-line.entity';

const buttonStyle = { padding: '1px 0 5px 0!important' };

export class QuoteProducts extends React.Component<QuoteProductsProps, QuoteProductsState> {
    inputQuoteLines: IQuoteLineEntity[];

    constructor(props: QuoteProductsProps) {
        super(props);
        this.inputQuoteLines = props.quoteLines;
        this.state = {
            quoteLines: props.quoteLines,
            showDialogAddProduct: false,
            btwPercentage: props.rawQuote?.data.btw_percentage ?? 0.06,
            isDiscountPercentage: props.rawQuote?.data.discount_percentage !== undefined,
            discountAmount: props.rawQuote?.data.discount_amount ?? 0,
            discountPercentage: props.rawQuote?.data.discount_percentage ?? undefined,
            btwAmount: props.rawQuote?.data.btw_amount ?? 0,
            finalPriceToPay: props.rawQuote?.final_price_to_pay ?? 0,
            placementCost: props.rawQuote?.placement_cost ?? 0,
        };
        this.calculateTotalPriceQuote();
    }

    async componentDidMount() {
        const configuration = await configuration$;
        this.setState({ configuration });
    }

    publishChanges() {
        this.props.onChange({
            quoteLines: this.state.quoteLines ?? [],
            btwPercentage: this.state.btwPercentage,
            discountAmount: this.state.discountAmount,
            discountPercentage: this.state.discountPercentage,
            placementCost: this.state.placementCost,
        });
    }

    deleteQuoteLine(index: number) {
        this.state.quoteLines.splice(index, 1);
        this.setState({ quoteLines: this.state.quoteLines });
        this.calculateTotalPriceQuote();
    }

    calculateTotalPriceQuote() {
        if (this.state.quoteLines?.length === 0) {
            return this.setState({
                ...this.state,
                finalPriceToPay: 0,
                btwAmount: 0,
            });
        }

        const { discountAmount, btwAmount, finalPriceToPay } = calculateQuotePrices(
            this.state.quoteLines,
            this.state.placementCost,
            this.state.btwPercentage,
            this.state.discountAmount,
            this.state.discountPercentage,
        );

        this.setState({ discountAmount, btwAmount, finalPriceToPay }, this.publishChanges);
    }

    setBtwPercentage = event => {
        this.setState(
            {
                btwPercentage: parseFloat(event.target.value),
            },
            () => this.calculateTotalPriceQuote(),
        );
    };

    setPlacementCost = async event => {
        this.setState(
            {
                placementCost: parseFloat(event.target.value),
            },
            () => {
                this.calculateTotalPriceQuote();
            },
        );
    };

    setDiscountAmount = async event => {
        this.setState(
            {
                discountPercentage: undefined,
                discountAmount: parseFloat(event.target.value),
            },
            () => this.calculateTotalPriceQuote(),
        );
    };

    transformQuoteLine(productLine: IProductLineEntity): IQuoteLineEntity {
        //  transformation productLine
        return {
            product: productLine.product,
            product_id: productLine.product.product_id,
            final_price_to_pay: productLine.final_price_to_pay,
            unit_price: productLine.unitPrice,
            quantity: productLine.quantity, // Default value for `quantity`
            quote_line_id: undefined,
            data: {},
            creation_date: new Date(),
            quote_id: undefined,
            unit: productLine.product.pricing.unit_type,
            product_document_free_entry: null,
            vat_tarif: null,
            discount_price: null,
        };
    }

    render() {
        return (
            <div id={'quote-products'}>
                <Card sx={{ minWidth: 275, maxWidth: '100%', marginTop: '10px' }}>
                    <CardHeader title={'Products'} />
                    <CardContent>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650, padding: '0px' }} size={'small'} aria-label={'a dense table'}>
                                <TableHead>
                                    <TableCell>Product nummer</TableCell>
                                    <TableCell>Product naam</TableCell>
                                    <TableCell>Beschrijving</TableCell>
                                    <TableCell>Aantal</TableCell>
                                    <TableCell>Stuk prijs</TableCell>
                                    <TableCell>Totaal</TableCell>
                                    <TableCell></TableCell>
                                </TableHead>
                                <TableBody sx={{ padding: '0px' }}>
                                    {this.state.quoteLines?.map((quoteLine, index) => (
                                        <TableRow key={index}>
                                            <TableCell>{quoteLine.product?.product_number}</TableCell>
                                            <TableCell>{quoteLine.product?.name}</TableCell>
                                            <TableCell>{quoteLine.product?.specifications?.description}</TableCell>
                                            <TableCell>{quoteLine.quantity}</TableCell>
                                            <TableCell>{quoteLine.unit_price}</TableCell>
                                            <TableCell>{quoteLine.final_price_to_pay} EUR</TableCell>
                                            <TableCell>
                                                {' '}
                                                <Button
                                                    variant={'text'}
                                                    startIcon={<DeleteIcon />}
                                                    onClick={() => this.deleteQuoteLine(index)}
                                                ></Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <Grid container direction={'row'} justifyContent={'center'} alignItems={'center'}>
                            <Grid item>
                                <Button
                                    variant={'text'}
                                    startIcon={<AddIcon />}
                                    onClick={() => this.setState({ showDialogAddProduct: true })}
                                >
                                    Add
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            spacing={3}
                            direction={'row'}
                            justifyContent={'space-between'}
                            alignItems={'flex-start'}
                            /*columns={{ xs: 4 , xs: 8, md: 2 }}*/
                        >
                            <Grid item> </Grid>
                            <Grid item>
                                <Grid
                                    container
                                    direction={'row'}
                                    justifyContent={'flex-start'}
                                    alignItems={'center'}
                                    spacing={1}
                                    sx={{ width: '300px', border: 'solid 1px' }}
                                >
                                    {this.state.configuration?.quotes.placementCostEnabled ? (
                                        <Grid container item spacing={1}>
                                            <Grid item xs={6}>
                                                Plaatsing kost
                                            </Grid>
                                            <Grid item>
                                                <Input
                                                    margin={'none'}
                                                    sx={{ width: '80px' }}
                                                    size={'small'}
                                                    id={'placement-cost'}
                                                    style={buttonStyle}
                                                    value={this.state.placementCost}
                                                    /* onChange={handleChange('amount')}*/
                                                    endAdornment={<InputAdornment position={'end'}>€</InputAdornment>}
                                                    type={'number'}
                                                    onChange={this.setPlacementCost}
                                                />
                                            </Grid>
                                        </Grid>
                                    ) : null}

                                    <Grid container item spacing={1}>
                                        <FormGroup style={{ marginLeft: '10px' }}>
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={this.state.isDiscountPercentage}
                                                        onChange={(event, checked) =>
                                                            this.setState({ isDiscountPercentage: checked })
                                                        }
                                                    />
                                                }
                                                label="Korting in %?"
                                            />
                                        </FormGroup>
                                    </Grid>
                                    <Grid container item spacing={1}>
                                        <Grid item xs={6}>
                                            Korting
                                        </Grid>

                                        <Grid item>
                                            {this.state.isDiscountPercentage ? (
                                                <Input
                                                    margin={'none'}
                                                    sx={{ width: '80px' }}
                                                    id={'discount-percentage'}
                                                    type={'number'}
                                                    size={'small'}
                                                    style={buttonStyle}
                                                    endAdornment={<InputAdornment position={'end'}>%</InputAdornment>}
                                                    onChange={event =>
                                                        this.setState(
                                                            {
                                                                discountPercentage: parseFloat(event.target.value),
                                                            },
                                                            () => this.calculateTotalPriceQuote(),
                                                        )
                                                    }
                                                    defaultValue={this.state.discountPercentage}
                                                />
                                            ) : (
                                                <Input
                                                    margin={'none'}
                                                    sx={{ width: '80px' }}
                                                    id={'discount-amount'}
                                                    type={'number'}
                                                    size={'small'}
                                                    style={buttonStyle}
                                                    endAdornment={<InputAdornment position={'end'}>€</InputAdornment>}
                                                    onChange={this.setDiscountAmount}
                                                    defaultValue={this.state.discountAmount}
                                                />
                                            )}
                                        </Grid>
                                    </Grid>
                                    <Grid container item spacing={1}>
                                        <Grid item xs={6}>
                                            BTW (
                                            <Select
                                                sx={{ marginLeft: '6px' }}
                                                labelId={'demo-simple-select-standard-label'}
                                                id={'btw-percentage'}
                                                size={'small'}
                                                variant={'standard'}
                                                style={buttonStyle}
                                                defaultValue={this.state.btwPercentage}
                                                onChange={this.setBtwPercentage}
                                            >
                                                <MenuItem value={0}>0%</MenuItem>
                                                <MenuItem value={0.06}>6%</MenuItem>
                                                <MenuItem value={0.21}>21%</MenuItem>
                                            </Select>
                                            )
                                        </Grid>
                                        <Grid item>
                                            <Input
                                                /*disabled*/
                                                margin={'none'}
                                                sx={{ width: '80px', opacity: 1, '-webkit-text-fill-color': 'black' }}
                                                size={'small'}
                                                id={'btw-amount'}
                                                style={buttonStyle}
                                                value={this.state.btwAmount}
                                                endAdornment={<InputAdornment position={'end'}>€</InputAdornment>}
                                                type={'number'}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid container item spacing={1}>
                                        <Grid>
                                            <Divider />
                                        </Grid>

                                        <Grid item xs={6}>
                                            <label style={{ fontWeight: 'bold' }}>Totaal</label>
                                        </Grid>
                                        <Grid item>
                                            <label style={{ fontWeight: 'bold' }}>{this.state.finalPriceToPay}</label>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
                {this.state.showDialogAddProduct ? (
                    <ProductAdd
                        upsertProductLine={productLine => {
                            const transformedQuoteLine = this.transformQuoteLine(productLine);
                            this.setState(
                                {
                                    quoteLines: [...(this.state.quoteLines ?? []), transformedQuoteLine],
                                    showDialogAddProduct: false,
                                },
                                () => this.calculateTotalPriceQuote(),
                            );
                        }}
                        showDialogAddProduct={this.state.showDialogAddProduct}
                        dialogClosed={() => this.setState({ showDialogAddProduct: false, quoteLines: [] })}
                    ></ProductAdd>
                ) : undefined}
            </div>
        );
    }
}

export default QuoteProducts;
