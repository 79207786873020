import {
    Box,
    Card,
    CardContent,
    CardHeader,
    FormControl,
    FormControlLabel,
    FormGroup,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    TextField,
} from '@mui/material';
import { ClientApi, ClientState, IAddress, IClientEntity } from '@phrospr/api-backend';
import React from 'react';
import ClientAutoSearch from '../../ClientAutoSearch/ClientAutoSearch';
import './ClientFiche.scss';
import { getDeliveryAddresses } from '@phrospr/lib-core';
import Edit from '@mui/icons-material/Edit';
import { ClientFicheProps, ClientFicheState } from './ClientFiche.types';
import ClientFicheEdit from '../ClientFicheEdit/ClientFicheEdit';

export class ClientFiche extends React.Component<ClientFicheProps, ClientFicheState> {
    constructor(props: ClientFicheProps) {
        super(props);
        if (this.props.selectedClient) {
            const deliveryAddresses = getDeliveryAddresses(this.props.selectedClient);
            let selectedAddressIndex = 0;
            if (this.props.selectedAddress) {
                selectedAddressIndex = deliveryAddresses.findIndex(
                    x => x.address === this.props.selectedAddress.address,
                );
                if (selectedAddressIndex === -1) selectedAddressIndex = 0;
            }
            this.state = {
                selectedClient: this.props.selectedClient,
                selectedAddress: this.props.selectedAddress,
                deliveryAddresses,
                selectedAddressIndex,
                isNewClient: false,
                newClient: {
                    first_name: '',
                    last_name: '',
                    address: '',
                    city: '',
                    zip_code: '',
                    phone: '',
                    email: '',
                    bank_account: '',
                },
                editMode: true,
            };
        } else {
            this.state = {
                selectedClient: null,
                deliveryAddresses: [],
                selectedAddressIndex: 0,
                isNewClient: false,
                newClient: {
                    first_name: '',
                    last_name: '',
                    address: '',
                    city: '',
                    zip_code: '',
                    phone: '',
                    email: '',
                    bank_account: '',
                },
                selectedAddress: undefined,
                editMode: false,
            };
        }
    }

    getMainAddress() {
        if (!this.state.selectedClient) return '';
        const { main_address } = this.state.selectedClient.client_data;
        if (!main_address) return '';
        return (
            <div>
                <div>{main_address.address}</div>
                <div>
                    {main_address.city} {main_address.zip_code}
                </div>
                <div>
                    {main_address.countryName} - {main_address.country}
                </div>
                <div>{main_address.state}</div>
            </div>
        );
    }

    async loadClient(client: IClientEntity) {
        const deliveryAddresses = getDeliveryAddresses(client);
        this.setState({
            selectedClient: client,
            deliveryAddresses,
            selectedAddressIndex: 0,
        });
        this.props.onChange({
            client: client,
            address: deliveryAddresses[0],
            isNewClient: this.state.isNewClient,
        });
    }

    async RefreshClientAfterEdit() {
        /*I do this here so i get the correct object back and since the client ID is unique this should be OK*/
        await this.loadClient(
            (
                await ClientApi.listClients({
                    filter: {
                        clientId: this.state.selectedClient.client_id,
                    },
                })
            ).data.clients[0],
        );
    }

    setDeliveryAddress(index: number) {
        this.setState({ selectedAddressIndex: index, selectedAddress: this.state.deliveryAddresses[index] }, () => {
            this.props.onChange({
                client: this.state.selectedClient,
                address: this.state.selectedAddress,
                isNewClient: this.state.isNewClient,
            });
        });
    }

    async updateNewClient() {
        const newClient = this.state.newClient;
        const address = {
            address: newClient.address,
            city: newClient.city,
            zip_code: newClient.zip_code,
        };
        this.props.onChange({
            isNewClient: this.state.isNewClient,
            client: {
                exact_client_number: null,
                exact_last_sync: null,
                state: ClientState.lead,
                client_settings: {
                    mailSettings: {
                        disableSendMailForPlannedNotification: false,
                        disableSendMailNotifyPlanningItemForHisAppointment: false,
                        disableFeedbackMail: false,
                        disableSendMailForUpcomingMaintenance: false,
                    },
                    maintenanceSettings: {
                        active: true,
                        inactive_date: null,
                    },
                },
                client_data: {
                    email: newClient.email,
                    mobile_phone: newClient.phone,
                    telephone_phone: newClient.phone,
                    main_address: address,
                    first_name: newClient.first_name,
                    last_name: newClient.last_name,
                    company_data: {},
                },
                inactive: false,
            },
            address,
        });
    }

    toggleNewClient(checked: boolean) {
        this.setState({
            isNewClient: !checked,
        });
        this.updateNewClient();
    }

    render() {
        return (
            <Card sx={{ minWidth: 275 }} style={{ maxWidth: '600px' }}>
                <CardHeader title="Klanten fiche" />
                <CardContent>
                    {!this.state.editMode ? (
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Switch
                                        size="small"
                                        defaultChecked={!this.state.isNewClient}
                                        onChange={(event, checked) => {
                                            this.setState({
                                                isNewClient: !checked,
                                            });
                                        }}
                                    />
                                }
                                label="Bestaande klant/lead"
                            />
                        </FormGroup>
                    ) : (
                        <div></div>
                    )}

                    {this.state.isNewClient ? (
                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 1, width: '25ch' },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <TextField
                                required
                                id="first_name"
                                label="Voornaam"
                                onChange={event => {
                                    this.setState(
                                        {
                                            newClient: { ...this.state.newClient, first_name: event.target.value },
                                        },
                                        () => this.updateNewClient(),
                                    );
                                }}
                                defaultValue={this.state.newClient.first_name}
                                variant="standard"
                            />
                            <TextField
                                required
                                id="last_name"
                                label="Achternaam"
                                onChange={event => {
                                    this.setState(
                                        {
                                            newClient: { ...this.state.newClient, last_name: event.target.value },
                                        },
                                        () => this.updateNewClient(),
                                    );
                                }}
                                defaultValue={this.state.newClient.last_name}
                                variant="standard"
                            />
                            <TextField
                                required
                                id="address"
                                label="Adres"
                                onChange={event => {
                                    this.setState(
                                        {
                                            newClient: { ...this.state.newClient, address: event.target.value },
                                        },
                                        () => this.updateNewClient(),
                                    );
                                }}
                                defaultValue={this.state.newClient.address}
                                variant="standard"
                            />
                            <TextField
                                required
                                id="city"
                                label="Stad"
                                onChange={event => {
                                    this.setState(
                                        {
                                            newClient: { ...this.state.newClient, city: event.target.value },
                                        },
                                        () => this.updateNewClient(),
                                    );
                                }}
                                defaultValue={this.state.newClient.city}
                                variant="standard"
                            />
                            <TextField
                                required
                                id="postcode"
                                label="Postcode"
                                onChange={event => {
                                    this.setState(
                                        {
                                            newClient: { ...this.state.newClient, zip_code: event.target.value },
                                        },
                                        () => this.updateNewClient(),
                                    );
                                }}
                                defaultValue={this.state.newClient.zip_code}
                                variant="standard"
                            />
                            <TextField
                                required
                                id="phone"
                                label="Tel nr."
                                onChange={event => {
                                    this.setState(
                                        {
                                            newClient: { ...this.state.newClient, phone: event.target.value },
                                        },
                                        () => this.updateNewClient(),
                                    );
                                }}
                                defaultValue={this.state.newClient.phone}
                                variant="standard"
                            />
                            <TextField
                                required
                                id="email"
                                label="Email"
                                onChange={event => {
                                    this.setState(
                                        {
                                            newClient: { ...this.state.newClient, email: event.target.value },
                                        },
                                        () => this.updateNewClient(),
                                    );
                                }}
                                defaultValue={this.state.newClient.email}
                                variant="standard"
                            />
                            <TextField
                                required
                                id="bank_account"
                                label="Rekeningnummer"
                                onChange={event => {
                                    this.setState(
                                        {
                                            newClient: { ...this.state.newClient, bank_account: event.target.value },
                                        },
                                        () => this.updateNewClient(),
                                    );
                                }}
                                defaultValue={this.state.newClient.bank_account}
                                variant="standard"
                            />
                        </Box>
                    ) : (
                        <div>
                            {!this.state.editMode ? (
                                <ClientAutoSearch onClientChange={selectedClient => this.loadClient(selectedClient)} />
                            ) : (
                                ''
                            )}

                            <TableContainer>
                                <Table
                                    sx={{ minWidth: 550, padding: '0 0', marginTop: '5px', marginBottom: '5px' }}
                                    size="small"
                                    aria-label="a dense table"
                                >
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>Naam:</TableCell>
                                            <TableCell>
                                                {this.state.selectedClient?.client_data.first_name}{' '}
                                                {this.state.selectedClient?.client_data.last_name}
                                                {this.state.selectedClient ? (
                                                    <IconButton
                                                        size="small"
                                                        aria-label="edit"
                                                        onClick={() =>
                                                            this.setState({
                                                                editClientMode: true,
                                                            })
                                                        }
                                                    >
                                                        {' '}
                                                        <Edit />
                                                    </IconButton>
                                                ) : (
                                                    ''
                                                )}
                                            </TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell>Email:</TableCell>
                                            <TableCell>{this.state.selectedClient?.client_data.email}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Tel nr:</TableCell>
                                            <TableCell>
                                                {this.state.selectedClient?.client_data.telephone_phone}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Gsm nr:</TableCell>
                                            <TableCell>{this.state.selectedClient?.client_data.mobile_phone}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Bankrekening nr:</TableCell>
                                            <TableCell>
                                                {this.state.selectedClient?.client_data.company_data?.bank_account}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>BTW nr:</TableCell>
                                            <TableCell>
                                                {this.state.selectedClient?.client_data.company_data?.bank_account}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Hoofdadres:</TableCell>
                                            <TableCell>{this.getMainAddress()}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <FormControl fullWidth>
                                <InputLabel id="client-adres-label">Uitvoeringsadres</InputLabel>
                                <Select
                                    labelId="client-adres-label"
                                    id="client-adres"
                                    label="Uitvoeringsadres"
                                    value={this.state.selectedAddressIndex}
                                    onChange={event => this.setDeliveryAddress(event.target.value as number)}
                                    size="small"
                                >
                                    {this.state.deliveryAddresses.map((address, index) => {
                                        return (
                                            <MenuItem key={index} value={index}>
                                                {address.address}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </div>
                    )}
                </CardContent>
                {this.state.editClientMode ? (
                    <ClientFicheEdit
                        open={this.state.editClientMode}
                        client={this.state.selectedClient as IClientEntity}
                        selectedDeliveryAddress={
                            (this.state.selectedAddress
                                ? this.state.selectedAddress
                                : this.state.deliveryAddresses[0]) as IAddress
                        }
                        selectedDeliveryAddressIndex={this.state.selectedAddressIndex as number}
                        clientSaved={() => this.RefreshClientAfterEdit()}
                        dialogClosed={() => this.setState({ editClientMode: false })}
                    />
                ) : undefined}
            </Card>
        );
    }
}

export default ClientFiche;
