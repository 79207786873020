export enum QuoteLineColumns {
    quote_line_id = 'quote_line_id',
    quote_id = 'quote_id',
    product_id = 'product_id',
    creation_date = 'creation_date',
    product_document_free_entry = 'product_document_free_entry',
    discount_price = 'discount_price',
    vat_tarif = 'vat_tarif',
    quantity = 'quantity',
    unit_price = 'unit_price',
    unit = 'unit',
    final_price_to_pay = 'final_price_to_pay',
    data = 'data',
}

export interface QuoteLineAttributes {
    quote_line_id: number;
    quote_id: number | null;
    product_id: number | null;
    creation_date: Date | null;
    product_document_free_entry: any | null;
    discount_price: number | null;
    vat_tarif: number | null;
    quantity: number | null;
    unit_price: number | null;
    unit: string | null;
    final_price_to_pay: number | null;
    data: any | null;
}

export type QuoteLineOptionalAttributes =
    | 'quote_line_id'
    | 'quote_id'
    | 'product_id'
    | 'creation_date'
    | 'product_document_free_entry'
    | 'discount_price'
    | 'vat_tarif'
    | 'quantity'
    | 'unit_price'
    | 'unit'
    | 'final_price_to_pay'
    | 'data';

export interface QuoteLineCreate {
    quote_line_id?: number;
    quote_id?: number | null;
    product_id: number | null;
    creation_date?: Date | null;
    product_document_free_entry?: any | null;
    discount_price?: number | null;
    vat_tarif?: number | null;
    quantity: number | null;
    unit_price?: number | null;
    unit?: string | null;
    final_price_to_pay?: number | null;
    data?: any | null;
}
