import { Box, Button, CircularProgress, Grid } from '@mui/material';
import Stack from '@mui/material/Stack';
import { ClientApi, WorkOrderApi, WorkOrderStatus } from '@phrospr/api-backend';
import React, { Component } from 'react';
import * as toastr from 'toastr';
import './WorkOrderUpsertPage.scss';
import { IWorkOrderEntity, IWorkOrderLineEntity } from '@phrospr/lib-models';
import WorkOrderProducts from '../WorkOrderProducts/WorkOrderProducts';
import WorkOrderInfo from '../WorkOrderInfo/WorkOrderInfo';
import { WorkOrderUpsertPageProps, WorkOrderUpsertState } from './WorkOrderUpsertPage.types';
import { ClientFiche } from '../../Client';

let isUpserting = false;

export class WorkOrderUpsertPage extends Component<WorkOrderUpsertPageProps, WorkOrderUpsertState> {
    message: string;
    workOrderId?: number;
    clientId?: number;
    rawWorkOrder: IWorkOrderEntity;

    constructor(props: WorkOrderUpsertPageProps) {
        console.log('i get here');
        super(props);
        this.workOrderId = props.workOrderId;
        this.clientId = props.clientId;
        this.state = {
            loading: true,
            message: '',
            selectedClient: undefined,
            selectedAddress: undefined,
            workOrder: {
                work_order_id: props.workOrderId,
                data: {
                    created_by_user_id: null,
                    work_order_technician_user_id: null,
                    work_time_registration: [],
                    travel_distance: null,
                },
                status: WorkOrderStatus.created,
                client: null,
                work_order_type: null,
            },
            workOrderLines: [],
        };
    }

    async componentDidMount() {
        if (this.workOrderId !== undefined) {
            const workOrder = (await WorkOrderApi.getWorkOrder(this.workOrderId)).data;
            this.rawWorkOrder = workOrder;
            this.setState({
                selectedClient: workOrder.client,
                selectedAddress: workOrder.data.address,
                workOrder,
                workOrderLines: workOrder.work_order_lines ?? [],
            });
        }

        if (this.clientId) {
            const client = (await ClientApi.getClient(this.clientId)).data;
            this.setState({ selectedClient: client, selectedAddress: client.client_data.main_address });
        }

        this.setState({ loading: false });
    }

    async upsertWorkOrder() {
        if (isUpserting) return;
        this.setState({});
        isUpserting = true;
        if (this.state.workOrderLines.length <= 0) {
            toastr.error('Geen werbkon lijnen geselecteerd');
            isUpserting = false;
            return;
        }
        if (!this.state.selectedClient) {
            toastr.error('Geen klant geselecteerd');
            isUpserting = false;
            return;
        }
        if (!this.state.workOrder.data.work_order_technician_user_id) {
            toastr.error('Geen technieker geselecteerd');
            isUpserting = false;
            return;
        }
        if (!this.state.selectedAddress) {
            toastr.error('Geen leveradres geselecteerd');
            isUpserting = false;
            return;
        }

        try {
            const workOrder = this.state.workOrder;
            if (!workOrder.data)
                workOrder.data = {
                    created_by_user_id: null,
                    work_order_technician_user_id: null,
                    work_time_registration: [],
                    travel_distance: null,
                };

            workOrder.work_order_lines = this.state.workOrderLines as IWorkOrderLineEntity[];
            workOrder.data.address = this.state.selectedAddress;
            await WorkOrderApi.upsertWorkOrder({
                client_id: this.state.selectedClient?.client_id,
                work_order_lines: this.state.workOrderLines,
                work_order_id: this.workOrderId,
                data: this.state.workOrder.data,
                work_order_type: this.state.workOrder.work_order_type,
                status: this.state.workOrder.status,
                planning_item_id: this.state.workOrder.planning_item_id,
            });
            this.props.onSuccess();
        } catch (e) {
            console.error(e);
        }
        isUpserting = false;
    }

    cancel() {
        this.props.onCancel();
    }

    render() {
        if (this.state.loading)
            return (
                <Box sx={{ display: 'flex' }}>
                    <CircularProgress />
                </Box>
            );
        return (
            <div>
                <Grid
                    container
                    spacing={3}
                    direction={'row'}
                    justifyContent={'space-between'}
                    alignItems={'flex-start'}
                >
                    <Grid item>
                        <ClientFiche
                            selectedClient={this.state.selectedClient}
                            selectedAddress={this.state.selectedAddress}
                            onChange={({ client: selectedClient, address }) => {
                                this.setState({ selectedClient, selectedAddress: address });
                            }}
                        ></ClientFiche>
                    </Grid>
                    <Grid item>
                        <WorkOrderInfo
                            workOrder={this.rawWorkOrder}
                            onChange={({ workOrder }) =>
                                this.setState({ workOrder: { ...this.state.workOrder, ...workOrder } })
                            }
                        ></WorkOrderInfo>
                    </Grid>
                </Grid>
                <WorkOrderProducts
                    rawWorkOrder={this.rawWorkOrder}
                    workOrderLines={this.rawWorkOrder?.work_order_lines ?? []}
                    onChange={workOrderLines => this.setState({ ...workOrderLines })}
                />
                <Grid
                    sx={{ marginTop: '10px' }}
                    container
                    direction={'row'}
                    justifyContent={'flex-end'}
                    alignItems={'flex-start'}
                >
                    <Grid item>
                        <Stack spacing={2} direction={'row'}>
                            <Button variant={'outlined'} onClick={() => this.cancel()}>
                                Cancel
                            </Button>
                            <Button variant={'contained'} onClick={() => this.upsertWorkOrder()}>
                                Opslaan
                            </Button>
                        </Stack>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default WorkOrderUpsertPage;
