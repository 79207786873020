import {
    Box,
    Button,
    ChipProps,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    TextField,
} from '@mui/material';
import React, { Component } from 'react';
import * as toastr from 'toastr';
import { WorkOrderOverviewProps, WorkOrderOverviewState } from './WorkOrderOverview.types';
import {
    IActivitySearchEntitySort,
    IWorkOrderEntity,
    IWorkOrderSearchEntity,
    IWorkOrderSearchFilterEntity,
    RequestSort,
    WorkOrderApi,
    WorkOrderCommentApi,
    WorkOrderStatus,
} from '@phrospr/api-backend';
import { DataGrid, GridActionsCellItem, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';
import EditIcon from '@mui/icons-material/Edit';
import MessageIcon from '@mui/icons-material/Message';
import Chip from '@mui/material/Chip';
import { amber, blue, green, red, yellow } from '@mui/material/colors';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import DoneIcon from '@mui/icons-material/Done';
import WorkOrderFilter from './WorkOrderFilter/WorkOrderFilter';
import { GridEnrichedColDef } from '@mui/x-data-grid/models/colDef/gridColDef';
import { downloadFileFromApi } from '@phrospr/lib-web-core';
import { CustomNoRowsOverlay } from './WorkOrderOverviewEmpty';
import { LinkWithRouter } from '../LinkWithRouter';
import { IClientEntity } from '@phrospr/lib-models';
import { getPlanningItemTypeNl, getQuoteVersion } from '@phrospr/lib-core';
import CheckIcon from '@mui/icons-material/Check';
import ReceiptIcon from '@mui/icons-material/Receipt';
import moment from 'moment';

export class WorkOrderOverview extends Component<WorkOrderOverviewProps, WorkOrderOverviewState> {
    workOrderFilter: IWorkOrderSearchEntity = {
        filter: {
            client_name: '',
        },
        limit: 20,
        show: {
            client: true,
            work_order_technician_user: true,
            planning_item: true,
        },
        sort: { column: 'work_order_id', order: RequestSort.DESC },
    };

    datagrid: {
        rowLength: 10;
        maxColumns: 11;
    };

    columns: GridEnrichedColDef[] = [
        { field: 'work_order_id', headerName: 'ID', width: 70 },
        {
            field: 'creation_date',
            headerName: 'Datum',
            type: 'date',
            width: 100,
            editable: false,
            sortable: false,
        },
        { field: 'work_order_type', headerName: 'Type werkbon', minWidth: 90, flex: 0.5 },
        {
            field: 'exact_client_number',
            headerName: 'Exact nr.',
            width: 80,
            hide: false,
            valueGetter: (params: GridValueGetterParams) => params.row.client,
            renderCell: params => {
                const client = params.value as IClientEntity;
                return (
                    <LinkWithRouter
                        url={`/client-detail?client_id=${client.client_id}`}
                        text={client.exact_client_number ?? ''}
                        routeNavigator={this.props.routeNavigator}
                    ></LinkWithRouter>
                );
            },
        },
        {
            field: 'client_id',
            headerName: 'Voornaam',
            width: 120,
            flex: 0.8,
            editable: false,
            valueGetter: this.getFirstName,
            sortable: false,
        },
        {
            field: 'client.client_data.first_name',
            headerName: 'Achternaam',
            width: 120,
            flex: 0.8,
            editable: false,
            valueGetter: this.getLastName,
            sortable: false,
        },
        {
            field: 'planning_item_id',
            headerName: 'Planning item',
            width: 60,
            flex: 0.5,
            valueGetter: params => params.row,
            renderCell: params => {
                const workOrder = params.value as IWorkOrderEntity;
                const planningItem = workOrder.planning_item;
                if (!planningItem) return <></>;
                return (
                    <LinkWithRouter
                        url={`/planning-item-detail?planning_item_id=${planningItem.planning_item_id}`}
                        text={`${getPlanningItemTypeNl(planningItem.planning_item_type)} ${
                            planningItem.planning_item_type_number
                        }`}
                        routeNavigator={this.props.routeNavigator}
                    ></LinkWithRouter>
                );
            },
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 90,
            renderCell: params => {
                return <Chip label="Small" size="small" variant="outlined" {...this.getChipProps(params)} />;
            },
            sortable: false,
            flex: 0.5,
        },
        {
            field: 'invoice_number',
            headerName: 'Invoice nr.',
            width: 50,
            flex: 0.6,
            valueGetter: this.getInvoiceNumber,
            sortable: false,
        },
        {
            field: 'technician',
            headerName: 'Technieker',
            width: 150,
            flex: 0.8,
            valueGetter: this.getTechnicianName,
            sortable: false,
        },
        {
            field: 'actions',
            type: 'actions',
            width: 105,
            getActions: params => [
                <GridActionsCellItem
                    icon={<DownloadIcon />}
                    label="Download"
                    onClick={() => this.downloadWorkOrder(params.row)}
                />,
                <GridActionsCellItem
                    icon={<CloseIcon />}
                    label="Delete"
                    onClick={() => this.showCancelWorkorder(params.row)}
                    disabled={params.row.status !== WorkOrderStatus.created}
                />,
                <GridActionsCellItem
                    icon={<EditIcon />}
                    label="Edit"
                    onClick={() => this.editWorkOrder(params.row.work_order_id)}
                    showInMenu
                    disabled={params.row.status !== WorkOrderStatus.created}
                />,
                <GridActionsCellItem
                    icon={<MessageIcon />}
                    label="Comments"
                    onClick={() => this.showComments(params.row.work_order_id)}
                    showInMenu
                />,
                <GridActionsCellItem
                    icon={<CheckIcon />}
                    label="Finish Werkbon"
                    onClick={() => this.finishWorkOrder(params.row)}
                    showInMenu
                    disabled={params.row.status !== WorkOrderStatus.created}
                />,
                <GridActionsCellItem
                    icon={<ReceiptIcon />}
                    label="Invoice Werkbon"
                    onClick={() => this.showInvoiceWorkorder(params.row)}
                    showInMenu
                    disabled={params.row.status !== WorkOrderStatus.finished}
                />,
            ],
        },
    ];

    /*cancelWorkOrderModal = false;*/

    constructor(props: WorkOrderOverviewProps) {
        super(props);
        this.state = {
            editMode: false,
            cancelModal: false,
            workOrders: [],
            cancelWorkOrder: null,
            editWorkOrder: null,
            loading: false,
            invoiceWorkOrder: null,
            invoiceModal: false,
            workOrderComments: null,
            showCommentsModal: false,
        };
    }

    handleSortModeChange = (data: { field: string; sort: 'asc' | 'desc' }[]) => {
        let searchSort: IActivitySearchEntitySort = { column: 'work_order_id', order: RequestSort.DESC };
        if (data.length) {
            const { field, sort } = data[0];
            const order = sort === 'asc' ? RequestSort.ASC : RequestSort.DESC;
            searchSort = { column: field, order };
        }
        if (this.state.orderState?.order !== searchSort.order || this.state.orderState?.column !== searchSort.column) {
            this.setState({ orderState: searchSort }, this.loadWorkOrders);
        }
    };

    getChipProps(params: GridRenderCellParams): ChipProps {
        if (params.row.status === WorkOrderStatus.cancelled) {
            return {
                icon: <WarningIcon style={{ fill: red[500] }} />,
                label: params.value,
                style: {
                    borderColor: red[500],
                },
            };
        } else if (params.row.status === WorkOrderStatus.created) {
            return {
                icon: <InfoIcon style={{ fill: blue[500] }} />,
                label: params.value,
                style: {
                    borderColor: blue[500],
                },
            };
        } else if (params.row.status === WorkOrderStatus.invoiced) {
            return {
                icon: <ReceiptIcon style={{ fill: green[500] }} />,
                label: params.value,
                style: {
                    borderColor: green[500],
                },
            };
        } else if (params.row.status === WorkOrderStatus.finished) {
            return {
                icon: <CheckIcon style={{ fill: yellow[700] }} />,
                label: params.value,
                style: {
                    borderColor: yellow[700],
                },
            };
        } else {
            return {
                icon: <DoneIcon style={{ fill: amber[500] }} />,
                label: params.value,
                style: {
                    borderColor: amber[500],
                },
            };
        }
    }

    getFirstName(params: GridValueGetterParams) {
        return `${params.row.client.client_data.first_name || ''}`;
    }

    getLastName(params: GridValueGetterParams) {
        return `${params.row.client.client_data.last_name || ''}`;
    }

    getTechnicianName(params: GridValueGetterParams) {
        return `${params.row.work_order_technician_user.first_name || ''}`;
    }

    getInvoiceNumber(params: GridValueGetterParams) {
        return `${params.row.data.invoice_number || ''}`;
    }

    async setWorkOrderSearchFilter(workOrderSearchFilter: IWorkOrderSearchFilterEntity) {
        this.workOrderFilter.filter = workOrderSearchFilter;
        await this.loadWorkOrders();
    }

    loadWorkOrders = async () => {
        this.setState({ loading: true });
        if (this.state.orderState !== undefined) this.workOrderFilter.sort = this.state.orderState;
        const { total, records } = (
            await WorkOrderApi.getWorkOrderListPost({ work_order_filter: this.workOrderFilter })
        ).data;
        this.setState({ workOrders: records, editMode: true, loading: false, total });
    };

    async editWorkOrder(workOrderId: number) {
        this.props.goToEditWorkOrder(workOrderId);
    }

    async finishWorkOrder(workOrder: IWorkOrderEntity) {
        try {
            await WorkOrderApi.finishWorkOrder(workOrder);
            await this.loadWorkOrders();
        } catch (err) {
            console.error(err);
            toastr.error('Fout bij het afwerken van werkbon');
        }
    }

    showInvoiceWorkorder = async (workOrder: IWorkOrderEntity) => {
        if (workOrder && workOrder.status === WorkOrderStatus.finished) {
            this.setState({ invoiceWorkOrder: workOrder });
            this.setState({ invoiceModal: true });
        } else {
            toastr.error(
                'De werkbon is al geinvoiced of er ging iets mis met deze actie van de werkbon. Contacteer IT.',
            );
        }
    };
    invoiceWorkOrder = async () => {
        if (this.state.invoiceWorkOrder.data.invoice_number === undefined) {
            toastr.error('Geen invoice nummer opgegeven');
        } else {
            try {
                await WorkOrderApi.invoiceWorkOrder(this.state.invoiceWorkOrder);
                await this.loadWorkOrders();
                this.setState({ invoiceModal: false });
            } catch (err) {
                console.error(err);
                toastr.error('Fout bij het invoicen van werkbon. Invoice nummer is verplicht');
            }
            return;
        }
    };

    cancelInvoiceWorkOrder = async () => {
        this.setState({ invoiceWorkOrder: null });
        this.setState({ invoiceModal: false });
    };

    handleClose() {
        console.log('closed');
    }

    async downloadWorkOrder(workOrder: IWorkOrderEntity) {
        try {
            await downloadFileFromApi(
                await WorkOrderApi.downloadWorkOrder(workOrder.work_order_id, { responseType: 'blob' }),
            );
        } catch (err) {
            if (err && err.response.status === 404) {
                toastr.error('Geen PDF beschikbaar');
            } else {
                console.error(err);
                toastr.error('Fout tijdens het ophalen van de pdf');
            }
        }
        console.log('i get here');
    }

    async showComments(id: number) {
        try {
            const comments = (await WorkOrderCommentApi.listWorkOrderComments(id)).data;
            this.setState({ workOrderComments: comments, showCommentsModal: true });
        } catch (e) {
            console.error(e);
            toastr.error('Fout tijdens ophalen comments');
        }
    }

    cancelShowComments = async () => {
        this.setState({ workOrderComments: null, showCommentsModal: false });
    };

    showCancelWorkorder = async (workOrder: IWorkOrderEntity) => {
        if (workOrder && workOrder.status !== WorkOrderStatus.cancelled) {
            this.setState({ cancelWorkOrder: workOrder });
            this.setState({ cancelModal: true });
        } else {
            toastr.error(
                'De werkbon is al geannuleerd of er ging iets mis met de cancel van de werkbon. Contacteer IT.',
            );
        }
    };

    cancelWorkOrder = async () => {
        try {
            await WorkOrderApi.cancelWorkOrder(this.state.cancelWorkOrder.work_order_id);
            await this.loadWorkOrders();
            this.setState({ cancelModal: false });
        } catch (e) {
            console.error(e);
            toastr.error('Een probleem is opgedoken tijdens het annuleren van de werkbon');
        }
        return;
    };

    cancelCancelWorkOrder = async () => {
        this.setState({ cancelWorkOrder: null });
        this.setState({ cancelModal: false });
    };

    render() {
        return (
            <div>
                <div style={{ padding: '5px' }}>
                    <WorkOrderFilter
                        onChange={workOrderSearchFilter => this.setWorkOrderSearchFilter(workOrderSearchFilter)}
                    ></WorkOrderFilter>
                </div>
                <div
                    data-testid="WorkOrderOverviewDataGrid"
                    style={{ display: 'flex', height: '100%', padding: '5px' }}
                >
                    <Box sx={{ height: 750, width: '100%', flexGrow: 1 }}>
                        <DataGrid
                            sx={{ width: '100%' }}
                            disableColumnFilter
                            getRowId={row => row.work_order_id}
                            {...this.datagrid}
                            loading={this.state.loading}
                            rowHeight={30}
                            sortingMode="server"
                            onSortModelChange={this.handleSortModeChange}
                            rows={this.state.workOrders}
                            columns={this.columns}
                            paginationMode="server"
                            rowCount={this.state.total ?? 0}
                            pageSize={this.workOrderFilter.limit}
                            onPageChange={newPage => {
                                // starts from 0
                                this.workOrderFilter.offset = this.workOrderFilter.limit * newPage;
                                this.loadWorkOrders();
                            }}
                            rowsPerPageOptions={[5]}
                            {...this.state.workOrders}
                            components={{
                                NoRowsOverlay: CustomNoRowsOverlay,
                            }}
                        />
                    </Box>
                    <Dialog open={this.state.cancelModal} onClose={this.handleClose}>
                        <DialogContent>
                            <DialogContentText>
                                Are you sure you want to cancel the work order{' '}
                                {this.state.cancelWorkOrder?.work_order_id}?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button size="small" variant="text" onClick={this.cancelCancelWorkOrder}>
                                Cancel
                            </Button>
                            <Button color="error" variant="contained" size="small" onClick={this.cancelWorkOrder}>
                                Delete
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog open={this.state.invoiceModal} onClose={this.handleClose}>
                        <DialogContent>
                            <DialogContentText>
                                Vul het invoice nummer in voor werkbon Nr. {this.state.invoiceWorkOrder?.work_order_id}.
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    id="invoiceNumber"
                                    label="Invoice Nummer"
                                    fullWidth
                                    variant="standard"
                                    onChange={event =>
                                        this.setState({
                                            invoiceWorkOrder: {
                                                ...this.state.invoiceWorkOrder,
                                                data: {
                                                    ...this.state.invoiceWorkOrder.data,
                                                    invoice_number: event.target.value,
                                                },
                                            },
                                        })
                                    }
                                />
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button size="small" variant="text" onClick={this.cancelInvoiceWorkOrder}>
                                Cancel
                            </Button>
                            <Button color="error" variant="contained" size="small" onClick={this.invoiceWorkOrder}>
                                Save
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog open={this.state.showCommentsModal} onClose={this.handleClose}>
                        <DialogContent>
                            <DialogContentText>
                                <div></div>
                                {this.state.workOrderComments ? (
                                    <div>
                                        {this.state.workOrderComments.map(comment => (
                                            <div>
                                                {moment(comment.creation_date).format('DD/MM/YYYY HH:mm')} [
                                                {comment.created_by_user.first_name}] - {comment.data?.comment}
                                            </div>
                                        ))}
                                    </div>
                                ) : (
                                    'Geen comments'
                                )}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button size="small" variant="text" onClick={this.cancelShowComments}>
                                Cancel
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </div>
        );
    }
}

export default WorkOrderOverview;
