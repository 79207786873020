import { Button, DialogActions, FormControl, Grid, Input, InputAdornment, TextareaAutosize } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { IProductEntity, IQuoteLineEntity } from '@phrospr/api-backend';
import React from 'react';
import toastr from 'toastr';
import ProductSearch from '../ProductSearch/ProductSearch';
import './ProductAdd.scss';
import { ProductAddProps, ProductAddState } from './ProductAdd.types';
import { IQuoteLineCreateEntity, IWorkOrderLineEntity } from '@phrospr/lib-models';
import { IProductLineEntity } from '@phrospr/lib-models/src/product/product-line.entity';

export class ProductAdd extends React.Component<ProductAddProps, ProductAddState> {
    constructor(props: ProductAddProps) {
        super(props);
        this.state = {
            open: this.props.showDialogAddProduct,
            productLine: {
                quantity: 1,
                final_price_to_pay: 0,
                product: null,
                unitPrice: 0,
                description: null,
            },
        };
    }

    cancel() {
        this.setState({ open: false });
        if (this.props.dialogClosed) this.props.dialogClosed();
        console.log(this.state);
    }

    setProduct(product: IProductEntity) {
        if (!product) return;
        console.log('product set');
        this.setState({
            productLine: {
                quantity: 1,
                final_price_to_pay: product.pricing?.price_per_unit * this.state.productLine.quantity,
                product: product,
                unitPrice: product.pricing.price_per_unit,
                description: product.specifications.description,
            },
        });
    }

    upsertProduct() {
        if (
            !this.state.productLine.product.product_id ||
            typeof this.state.productLine.unitPrice !== 'number' ||
            !this.state.productLine.quantity
        ) {
            toastr.error('Volgende velden zijn verplicht: product, eenheid prijs, aantal en totaal');
            return;
        }
        const ProductLine: IProductLineEntity = this.state.productLine;
        this.setState({
            ...this.state,
            productLine: ProductLine,
        });
        this.props.upsertProductLine(ProductLine);
    }

    render() {
        return (
            <div>
                <Dialog open={this.state.open} onClose={this.cancel} maxWidth={'md'}>
                    <DialogTitle>Add product</DialogTitle>
                    <DialogContent>
                        <Grid
                            container
                            spacing={3}
                            direction={'row'}
                            justifyContent={'flex-start'}
                            alignItems={'flex-start'}
                        >
                            <Grid item xs={4}>
                                <div>Select product</div>
                            </Grid>
                            <Grid item xs={8}>
                                <ProductSearch onProductChange={product => this.setProduct(product)}></ProductSearch>
                            </Grid>
                            <Grid item xs={4}>
                                <div>Beschrijving</div>
                            </Grid>
                            <Grid item xs={8}>
                                <TextareaAutosize
                                    aria-label={'minimum height'}
                                    minRows={4}
                                    placeholder={'Type u opmerkingen of notities voor de offerte hier.'}
                                    style={{ width: 400 }}
                                    defaultValue={this.state.productLine.description ?? ''}
                                    onChange={event =>
                                        this.setState({
                                            productLine: {
                                                ...this.state.productLine,
                                                description: event.target.value,
                                            },
                                        })
                                    }
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <div>Aantal</div>
                            </Grid>
                            <Grid item xs={8}>
                                <FormControl sx={{ m: 1, width: '80px' }} variant={'outlined'}>
                                    <Input
                                        size={'small'}
                                        id={'outlined-adornment-weight'}
                                        endAdornment={<InputAdornment position={'end'}> </InputAdornment>}
                                        aria-describedby={'outlined-weight-helper-text'}
                                        inputProps={{
                                            'aria-label': 'weight',
                                        }}
                                        type={'number'}
                                        value={this.state.productLine.quantity}
                                        onChange={event =>
                                            this.setState({
                                                productLine: {
                                                    ...this.state.productLine,
                                                    quantity: parseFloat(event.target.value),
                                                },
                                            })
                                        }
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <div>Stuk prijs</div>
                            </Grid>
                            <Grid item xs={8}>
                                <FormControl sx={{ m: 1, width: '80px' }} variant={'outlined'}>
                                    <Input
                                        size={'small'}
                                        id={'outlined-adornment-weight'}
                                        endAdornment={<InputAdornment position={'end'}>€</InputAdornment>}
                                        aria-describedby={'outlined-weight-helper-text'}
                                        inputProps={{
                                            'aria-label': 'weight',
                                        }}
                                        type={'number'}
                                        value={this.state.productLine.unitPrice}
                                        onChange={event =>
                                            this.setState({
                                                productLine: {
                                                    ...this.state.productLine,
                                                    unitPrice: parseFloat(event.target.value),
                                                },
                                            })
                                        }
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <div>Totaal</div>
                            </Grid>
                            <Grid item xs={8}>
                                <label>
                                    {this.state.productLine.unitPrice === null ||
                                    this.state.productLine.quantity === null
                                        ? '/'
                                        : this.state.productLine.unitPrice * this.state.productLine.quantity}
                                </label>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.cancel()}>Cancel</Button>
                        <Button onClick={() => this.upsertProduct()}>Add</Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default ProductAdd;
