import {
    Avatar,
    Card,
    CardContent,
    CardHeader,
    Chip,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextareaAutosize,
    Typography,
} from '@mui/material';
import { IWorkOrderEntity, WorkOrderStatus, WorkOrderType } from '@phrospr/lib-models';
import React from 'react';
import * as toastr from 'toastr';
import './WorkOrderInfo.scss';
import { WorkOrderInfoProps, WorkOrderInfoState } from './WorkOrderInfo.types';
import { UserApi } from '@phrospr/api-backend';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export class WorkOrderInfo extends React.Component<WorkOrderInfoProps, WorkOrderInfoState> {
    constructor(props: WorkOrderInfoProps) {
        super(props);
        const { workOrder } = props;
        this.state = {
            selectedLanguage: workOrder?.data?.language ?? 'nl',
            users: [],
            selectedUserId: workOrder?.data.work_order_technician_user_id ?? null,
            remarks: workOrder?.data?.remarks ?? '',
            workOrderType: workOrder?.work_order_type ?? WorkOrderType.startUpSwimmingPool,
            selectedTravelDistance: workOrder?.data?.travel_distance,
        };
        this.loadUserList();
    }

    async loadUserList() {
        try {
            const users = (await UserApi.listUsers()).data.filter(user => !user.data.ignoreUserInSellerList);
            this.setState({ users: users });
        } catch (e) {
            console.log(e);
            toastr.error('something went wrong');
        }
    }

    updateWorkOrder() {
        const workOrder: IWorkOrderEntity = {
            work_order_id: undefined,
            creation_date: undefined,
            status: WorkOrderStatus.created,
            data: {
                work_order_technician_user_id: this.state.selectedUserId,
                remarks: this.state.remarks,
                language: this.state.selectedLanguage,
                travel_distance: this.state.selectedTravelDistance,
                work_time_registration: [],
                created_by_user_id: null,
            },
            work_order_type: this.state.workOrderType,
        };
        this.props.onChange({ workOrder });
    }

    render() {
        return (
            <div>
                <Card sx={{ minWidth: 275 }} style={{ maxWidth: '700px' }}>
                    <CardHeader title={'WorkOrder info'} />
                    <CardContent>
                        <Grid
                            container
                            spacing={1}
                            direction={'row'}
                            justifyContent={'flex-start'}
                            alignItems={'flex-start'}
                        >
                            {!!this.props.workOrder?.created_by_user ? (
                                <Grid item xs={12} md={6}>
                                    <Stack direction="row" spacing={2} alignItems="center">
                                        <Typography>Gemaakt door</Typography>
                                        <Chip
                                            label={`${this.props.workOrder?.created_by_user?.first_name} ${this.props.workOrder?.created_by_user?.last_name}`}
                                            variant={'outlined'}
                                            color={'primary'}
                                            avatar={<Avatar>T</Avatar>}
                                            size={'small'}
                                        />
                                    </Stack>
                                </Grid>
                            ) : undefined}

                            <Grid item xs={12}></Grid>

                            <Grid item xs={12} md={6}>
                                <FormControl fullWidth>
                                    <InputLabel id={'work-order-info-type-label'}>Type</InputLabel>
                                    <Select
                                        size={'small'}
                                        labelId={'work-order-type-label'}
                                        id={'work-order-type'}
                                        label={'Type'}
                                        value={this.state.workOrderType}
                                        defaultValue={this.state.workOrderType}
                                        onChange={event =>
                                            this.setState({ workOrderType: event.target.value as WorkOrderType }, () =>
                                                this.updateWorkOrder(),
                                            )
                                        }
                                    >
                                        {Object.values(WorkOrderType).map((type, index) => (
                                            <MenuItem value={type} key={index}>
                                                {type}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <FormControl fullWidth>
                                    <InputLabel id={'work-order-info-taal-label'}>Taal</InputLabel>
                                    <Select
                                        size={'small'}
                                        labelId={'work-order-info-taal-label'}
                                        id={'work-order-info-taal'}
                                        label={'Taal'}
                                        value={this.state.selectedLanguage}
                                        defaultValue={this.state.selectedLanguage}
                                        onChange={event =>
                                            this.setState({ selectedLanguage: event.target.value as string }, () =>
                                                this.updateWorkOrder(),
                                            )
                                        }
                                    >
                                        <MenuItem value={'nl'}>Nederlands</MenuItem>
                                        <MenuItem value={'fr'}>Frans</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <FormControl fullWidth>
                                    <InputLabel id={'work-order-technician-label'}>Technician</InputLabel>
                                    <Select
                                        size={'small'}
                                        labelId={'work-order-technician-label'}
                                        id={'work-order-technician'}
                                        label={'Technician'}
                                        value={this.state.selectedUserId}
                                        onChange={event =>
                                            this.setState({ selectedUserId: event.target.value as number }, () =>
                                                this.updateWorkOrder(),
                                            )
                                        }
                                    >
                                        {this.state.users.map((user, index) => {
                                            return (
                                                <MenuItem value={user.user_id ?? undefined} key={index}>
                                                    {user.first_name} {user.last_name}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={6}>
                                <FormControl fullWidth>
                                    <InputLabel id={'work-order-info-taal-label'}>Taal</InputLabel>
                                    <Select
                                        size={'small'}
                                        labelId={'work-order-info-taal-label'}
                                        id={'work-order-info-taal'}
                                        label={'Verplaatsing forfaiteir'}
                                        value={this.state.selectedTravelDistance}
                                        defaultValue={this.state.selectedTravelDistance}
                                        onChange={event =>
                                            this.setState(
                                                { selectedTravelDistance: event.target.value as number },
                                                () => this.updateWorkOrder(),
                                            )
                                        }
                                    >
                                        <MenuItem value={1}>Zone 1</MenuItem>
                                        <MenuItem value={2}>Zone 2</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                                <TextareaAutosize
                                    aria-label={'minimum height'}
                                    minRows={4}
                                    defaultValue={this.state.remarks}
                                    onChange={event =>
                                        this.setState({ remarks: event.target.value }, () => this.updateWorkOrder())
                                    }
                                    placeholder={'Type u opmerkingen of notities voor de werkbon hier.'}
                                    style={{ width: 600 }}
                                />
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </div>
        );
    }
}

export default WorkOrderInfo;
