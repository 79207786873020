import { RouterModule, Routes } from '@angular/router';
import { HomePageComponent } from './containers/home-page/home-page.component';
import { MaintenancePageComponent } from './modules/maintenance/containers/maintenance-page/maintenance-page.component';
import { PreferencesPageComponent } from './modules/preferences/containers/preferences-page/preferences-page.component';
import { AuthorizationGuard } from './modules/shared/authorization.guard';
import { ClientPageComponent } from './modules/client/containers/client-page/client-page.component';
import { LeadsPageComponent } from './modules/leads/containers/leads-page/leads-page.component';
import { CalendarViewPageComponent } from './modules/calendar/containers/calendar-view-page/calendar-view-page.component';
import { ClientDetailPageComponent } from './modules/client/containers/client-detail-page/client-detail-page.component';
import { DeliveryPageComponent } from './modules/delivery/containers/delivery-page/delivery-page.component';
import { PlanningItemDetailPageComponent } from './modules/planning-item/containers/planning-item-detail-page/planning-item-detail-page.component';
import { PlanningItemHistoryPageContainer } from './modules/planning-item/containers/planning-item-history-page/planning-item-history-page.container';
import { RepairPageComponent } from './modules/repair/containers/repair-page/repair-page.component';
import { PlacementPageComponent } from './modules/placement/containers/placement-page/placement-page.component';
import { QuotePageComponent } from './modules/quote/containers/quote-page/quote-page.component';
import { LoadingPageComponent } from './containers/loading-page/loading-page.component';
import { QuoteDetailPageComponent } from './modules/quote/containers/quote-detail-page/quote-detail-page.component';
import { UpsertQuotePageComponent } from './modules/quote/containers/upsert-quote-page/upsert-quote-page.component';
import { QuoteStatsOverviewPageComponent } from './modules/quote/containers/quote-stats-overview-page/quote-stats-overview-page.component';
import { ActivityOverviewComponent } from './modules/activity/containers/activity-overview/activity-overview.component';
import { ProductPageComponent } from './modules/product/containers/product-page/product-page.component';
import { UpsertProductPageComponent } from './modules/product/containers/upsert-product-page/upsert-product-page.component';
import { UpsertQuotePage2Component } from './modules/quote/containers/upsert-quote-page2/upsert-quote-page2.component';
import { UserMaintenancePageComponent } from './modules/userMaintenance/containers/user-maintenance-page/user-maintenance-page.component';
import { ModuleWithProviders } from '@angular/core';
import { WorkOrderOverviewPageComponent } from './modules/work-order/work-order-overview-page/work-order-overview-page.component';
import { InventoryOverviewPageComponent } from './modules/inventory/inventory-overview-page/inventory-overview-page.component';
import { UpsertWorkOrderComponent } from './modules/work-order/upsert-work-order-page/upsert-work-order.component';

export const ROUTES: Routes = [
    { path: '', component: LoadingPageComponent },
    { path: 'load', component: LoadingPageComponent },
    { path: 'home', component: HomePageComponent },
    {
        path: 'maintenance',
        component: MaintenancePageComponent,
        data: { api: '/api/maintenance' },
        canActivate: [AuthorizationGuard],
    },
    { path: 'preferences', component: PreferencesPageComponent },
    { path: 'userMaintenance', component: UserMaintenancePageComponent, data: { api: '/api/user' } },
    { path: 'offer', component: QuotePageComponent },
    { path: 'leads', component: LeadsPageComponent },
    { path: 'client', component: ClientPageComponent },
    { path: 'calendar-view', component: CalendarViewPageComponent },
    { path: 'client-detail', component: ClientDetailPageComponent },
    { path: 'deliveries', component: DeliveryPageComponent },
    { path: 'planning-item-detail', component: PlanningItemDetailPageComponent },
    { path: 'planning-item-client-history', component: PlanningItemHistoryPageContainer },
    { path: 'repair', component: RepairPageComponent },
    { path: 'placement', component: PlacementPageComponent },
    { path: 'quote-detail-page', component: QuoteDetailPageComponent },
    { path: 'quote-upsert', component: UpsertQuotePageComponent },
    { path: 'quote-upsert2', component: UpsertQuotePage2Component },
    { path: 'quote-stats-overview-page', component: QuoteStatsOverviewPageComponent },
    { path: 'activity', component: ActivityOverviewComponent },
    { path: 'product', component: ProductPageComponent },
    { path: 'product-upsert', component: UpsertProductPageComponent },
    { path: 'work-order', component: WorkOrderOverviewPageComponent },
    { path: 'inventory', component: InventoryOverviewPageComponent },
    { path: 'work-order-upsert', component: UpsertWorkOrderComponent },

    { path: '**', redirectTo: '', pathMatch: 'full' },
];

export const ROUTING: ModuleWithProviders<any> = RouterModule.forRoot(ROUTES, { relativeLinkResolution: 'legacy' });
